.App {
  text-align: center;
}

p {
	line-height: 1.5;
}

table {
	font-size: 75%;
	margin-top: 30px;
	border-collapse: collapse;
	border-spacing: 0px;
	width: 100%;
	border: 0px solid #ddd;
	line-height: 1.5;
}

th, td {
	padding: 8px;
}

tr:nth-child(even){background-color: #EEEEEE33}

.App-logo {
  height: 50vmin;
}

.App-coins {
  position: relative;
  top: .125em;
  height: 0.9em;
  width: 0.9em;
}

.App-instructions {
  font-size: 70%;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.App-updated {
  font-size: 50%
}

.App-border {
  border: 0.5px solid white;
}

.User-status {
  background-color: #EEEEEE33;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 25px;
  margin-left: 10%;
  margin-right: 10%;
  -webkit-box-shadow: 8px 8px 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow:    8px 8px 5px 0px rgba(0, 0, 0, 0.16);
  box-shadow:         8px 8px 5px 0px rgba(0, 0, 0, 0.16);
}

.User-you {
  background-color: #74c475CC;
  border-radius: 5px;
  display: inline;
  padding: 3px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin 0.5s linear;
  }
}

@keyframes App-logo-spin {
  0% {
    transform: translate(-200%, 55%) scale(0.9) rotate(-860deg);
  }
   90% {
    transform: translate(0%, 0%) rotate(10deg);
  }
}
